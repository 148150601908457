import { useDispatch, useSelector } from 'react-redux';

import Toggle from 'components/Forms/Toggle';
import PageHeader from 'components/PageHeader';
import { setAlert } from 'features/alerts/alertsSlice';
import {
  fetchArchiveActivityLogsAfterInMonths,
  updateArchiveActivityLogsAfterInMonths,
  updateMaintenanceSettings,
} from 'features/settings/settingsSlice';
import { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import Loading from 'components/Loading';
import { Button } from 'components';
import usePermissions from 'hooks/usePermissions';

const QboIntegration = () => {
  const [loading, setLoading] = useState(false);
  const [qbo, setQbo] = useState(null);
  const qboAuthUrl = `${process.env.REACT_APP_API_BASE_URL}/qbo/authorize`;

  const checkQboConnection = async () => {
    setLoading(true);
    await axios
      .get('/qbo/companyinfo')
      .then((res) => setQbo(res.data.data))
      .catch((error) => setQbo(null))
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    checkQboConnection();
  }, []);

  const disconnect = async () => {
    setLoading(true);
    await axios
      .get('/qbo/disconnect')
      .then((res) => setQbo(null))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const connect = () => {
    window.location.href = qboAuthUrl;
  };

  return !loading ? (
    <div className="flex items-center ">
      {qbo ? (
        <>
          QBO Connected
          <button
            type="button"
            className="ml-4 border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center px-4 py-1.5 space-x-2"
            onClick={disconnect}
          >
            Disconnect
          </button>
        </>
      ) : (
        <>
          Connect to QBO
          <button
            type="button"
            className="ml-4 border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center px-4 py-1.5 space-x-2"
            onClick={connect}
          >
            Authorize
          </button>
        </>
      )}
    </div>
  ) : (
    <div className="flex items-center ">
      Checking QBO connection
      <Loading />
    </div>
  );
};

const Settings = () => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();

  const { maintenance, archiveActivityLogsAfterInMonths } = useSelector(
    (state) => state.settings
  );

  const [num, setNum] = useState('6');

  useEffect(() => {
    dispatch(fetchArchiveActivityLogsAfterInMonths());
  }, [dispatch]);

  useEffect(() => {
    setNum(archiveActivityLogsAfterInMonths);
  }, [archiveActivityLogsAfterInMonths]);

  const onChange = (value) => {
    dispatch(updateMaintenanceSettings(value)).then(({ error }) => {
      if (error) {
        dispatch(setAlert('error', 'Failed to update maintenance status'));
      }
    });
  };

  const onChangeArchiveActivityLogsAfterInMonths = () => {
    dispatch(updateArchiveActivityLogsAfterInMonths(num)).then(({ error }) => {
      if (error) {
        dispatch(setAlert('error', 'Failed to update maintenance status'));
      }
    });
  };

  return (
    <div>
      <PageHeader title="System Settings" />

      <div className="flex flex-col items-start text-left mt-12 space-y-12">
        <label className="flex items-center mr-4 text-grayscale-900 text-base font-inter font-bold tracking-3/4">
          App Maintenance Mode&nbsp;&nbsp;&nbsp;&nbsp;
          <Toggle
            checked={maintenance}
            onChange={onChange}
            disabled={!userCan('settings.maintenance.update')}
          />
        </label>

        <div>
          <label className="flex items-center mr-4 text-grayscale-900 text-base font-inter font-bold tracking-3/4">
            Archive Activity logs after:
          </label>

          <div className="flex space-x-5 items-center">
            <div className="flex space-x-2 items-center">
              <input
                type="number"
                className="mt-1 shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-gray-300 rounded-md"
                value={num}
                onChange={(e) => setNum(e.target.value)}
                disabled={!userCan('settings.ARCHIVE_LOGS_AFTER_MONTHS.update')}
              />

              <p>months</p>
            </div>

            <Button
              onClick={onChangeArchiveActivityLogsAfterInMonths}
              disabled={
                !!!num || !userCan('settings.ARCHIVE_LOGS_AFTER_MONTHS.update')
              }
            >
              Save
            </Button>
          </div>
        </div>

        <QboIntegration />
      </div>
    </div>
  );
};

export default Settings;
