import axios from 'axios';
import { object, string } from 'yup';
import { startCase, isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import { Modal, ModalHeader, Button } from 'components';
import { Label, RequiredAsterisk, Toggle } from 'components/Forms';

import { useRoles } from 'features/roles';
import { useAlerts } from 'features/alerts';

const FormModal = ({
  open,
  setOpen,
  onSuccess,
  currentRole,
  setCurrentRole,
}) => {
  const {
    CEO,
    roles,
    levels,
    defaultSorts,
    fetchAllRoles,
    subDepartments,
    departments,
  } = useRoles();

  const { popupAlert } = useAlerts();

  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedSubDepartment, setSelectedSubDepartment] = useState('');

  useEffect(() => {
    setSelectedDepartment(currentRole.department ?? '');
    setSelectedSubDepartment(currentRole.subDepartment ?? '');
  }, [currentRole]);

  useEffect(() => {
    const filters = [
      {
        attribute: 'level',
        operator: 'EQ',
        value: levels.AGENCY_LEVEL,
      },
      {
        attribute: 'status',
        operator: 'EQ',
        value: 'ACTIVE',
      },
      {
        attribute: 'code',
        operator: 'NOT_IN',
        value: [
          'SYSTEM_SUPER_USER',
          'AGENCY_SUPER_USER',
          'AGENCY_UNASSIGNED_EMPLOYEE',
        ],
      },
    ];

    if (selectedDepartment) {
      filters.push({
        attribute: 'department',
        operator: 'EQ',
        value: selectedDepartment,
      });
    }

    if (selectedSubDepartment) {
      filters.push({
        attribute: 'subDepartment',
        operator: 'EQ',
        value: selectedSubDepartment,
      });
    }

    let filterGroups = [{ filters }];

    if (selectedDepartment === departments.OUTGIVE_DEPARTMENT.value) {
      filterGroups.push({
        filters: [
          {
            attribute: 'code',
            operator: 'EQ',
            value: CEO.value,
          },
        ],
      });
    }

    fetchAllRoles({
      sorts: defaultSorts,
      filterGroups: JSON.stringify(filterGroups),
    });
  }, [selectedDepartment, selectedSubDepartment]);

  const validationSchema = object().shape({
    name: string().required('Required'),
    department: string().required('Required'),
    subDepartment: string().when('department', {
      is: 'OPERATIONS',
      then: string().required('Required'),
    }),
  });

  const closeModalActions = () => {
    setSelectedDepartment('');
    setSelectedSubDepartment('');
    setCurrentRole({ loading: true, data: {} });
  };

  const handleDepartmentChange = (newDepartment, setFieldValue) => {
    setSelectedDepartment(newDepartment);
    setSelectedSubDepartment('');
    setFieldValue('subDepartment', '');
  };

  const onSubmit = async (values, actions) => {
    const method = currentRole?.roleId ? 'put' : 'post';
    const url = currentRole?.roleId
      ? `/v2/roles/${currentRole.roleId}`
      : '/v2/roles';

    await axios[method](url, values)
      .then((response) => {
        popupAlert({ type: 'success', title: response.data.message });
        setSelectedDepartment('');
        setSelectedSubDepartment('');
        setOpen(false);
        onSuccess();
      })
      .catch((error) => {
        actions.setErrors(error.response.data.message);
      });

    actions.setSubmitting(false);
  };

  return (
    <div>
      <Modal open={open} persistent={true} as="div" align="top">
        <div className="inline-block max-w-lg my-8 p-8 text-left transition-all transform bg-white shadow-xl rounded-xl space-y-8 w-1/2">
          <ModalHeader
            title={
              <div className="flex items-center space-x-4">
                <span>
                  {isEmpty(currentRole)
                    ? 'Create new role'
                    : `Update ${currentRole.name}`}
                </span>
              </div>
            }
            onClose={() => {
              closeModalActions();
              setOpen(false);
            }}
            titleClasses="capitalize"
            border=""
            fontSize="text-xl"
            fontStyle="font-bold"
            px="px-0"
            py="py-0"
          />

          <Formik
            initialValues={{
              name: currentRole?.name ? currentRole?.name : '',
              department: currentRole?.department
                ? currentRole?.department
                : '',
              subDepartment: currentRole?.subDepartment
                ? currentRole?.subDepartment
                : '',
              supervisorRole: currentRole?.supervisorRole
                ? currentRole?.supervisorRole
                : '',
              level: levels.AGENCY_LEVEL,
              hasAccessToAllClients: currentRole?.hasAccessToAllClients
                ? currentRole?.hasAccessToAllClients
                : false,
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({
              handleChange,
              setFieldValue,
              values,
              isSubmitting,
              isValid,
              dirty,
            }) => (
              <Form className="">
                <div className="space-y-3">
                  <div className="">
                    <Label
                      htmlFor="department"
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Role Department <RequiredAsterisk />
                    </Label>

                    <Field
                      name="department"
                      as="select"
                      className="form-select disabled-white"
                      value={values.department}
                      disabled={currentRole?.isDefault}
                      onChange={(e) => {
                        handleChange(e);
                        handleDepartmentChange(e.target.value, setFieldValue);
                      }}
                    >
                      <option value="">Select Department</option>
                      {Object.entries(departments).map((t, i) => (
                        <option key={`department-${i}`} value={t[1].value}>
                          {startCase(t[1].value.toLowerCase())}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="department"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  {values.department === 'OPERATIONS' && (
                    <div>
                      <Label
                        htmlFor="subDepartment"
                        classes="text-left leading-1.5"
                        textColor="text-grayscale-800"
                        textSize="13"
                      >
                        Subdepartment <RequiredAsterisk />
                      </Label>
                      <Field
                        name="subDepartment"
                        as="select"
                        className="form-select disabled-white"
                        disabled={currentRole?.isDefault}
                        onChange={(e) => {
                          handleChange(e);
                          setSelectedSubDepartment(e.target.value);
                        }}
                      >
                        <option value="">Select Department</option>
                        {Object.entries(subDepartments).map((t, i) => (
                          <option key={`subDepartment-${i}`} value={t[1].value}>
                            {startCase(t[1].value.toLowerCase())}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="subDepartment"
                        component="div"
                        className="text-red-700 font-normal text-xs"
                      />
                    </div>
                  )}

                  <div className="">
                    <Label
                      htmlFor="name"
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Role Name <RequiredAsterisk />
                    </Label>
                    <Field
                      name="name"
                      placeholder="Enter Role Name"
                      disabled={currentRole?.isDefault}
                      className="form-input text-sm"
                      type="text"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="">
                    <Label
                      htmlFor="supervisorRole"
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Reporting Role <RequiredAsterisk />
                    </Label>
                    <Field
                      name="supervisorRole"
                      as="select"
                      className="form-select disabled-white"
                      disabled={currentRole?.isDefault}
                    >
                      <option value="">Select Reporting Role</option>
                      {roles?.data.map((t, i) => (
                        <option key={i} value={t.code}>
                          {t.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="supervisorRole"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>

                  <div className="space-y-1.5">
                    <Label
                      htmlFor="hasAccessToAllClients"
                      classes="text-left leading-1.5"
                      textColor="text-grayscale-800"
                      textSize="13"
                    >
                      Has Access To All clients
                    </Label>

                    <div>
                      <Toggle
                        checked={values.hasAccessToAllClients}
                        onChange={(value) =>
                          setFieldValue('hasAccessToAllClients', value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="flex mt-8">
                  <button
                    type="button"
                    onClick={() => {
                      closeModalActions();
                      setOpen(false);
                    }}
                    className="px-5 py-2.5 text-sm tracking-2 leading-1.5 font-bold text-secondary-light"
                  >
                    Cancel
                  </button>

                  <Button
                    type="submit"
                    roundedSize={40}
                    showLoading={true}
                    loading={isSubmitting}
                    disabled={!(isValid && dirty)}
                    className="text-sm font-bold tracking-2 bg-secondary text-grayscale-300 rounded-3xl px-8 py-2"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default FormModal;
