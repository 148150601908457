import axios from 'axios';
import { useState, useEffect } from 'react';
import { startCase, lowerCase, debounce } from 'lodash';
import { Link } from 'react-router-dom';

import {
  KeyIcon,
  PlusIcon,
  TrashIcon,
  PencilIcon,
  SearchIcon,
} from '@heroicons/react/outline';

import { useRoles, FormModal } from 'features/roles';
import { Navigation } from 'features/employees/components';

import { InputPrepend } from 'components/Forms';
import { Table, ConfirmationModal } from 'components';

import usePermissions from 'hooks/usePermissions';

import { useAlerts } from 'features/alerts';
import ReassignRoleModal from '../components/ReassignRoleModal';

const Roles = () => {
  const { popupAlert } = useAlerts();

  const { userCan, isAgencySuperUser } = usePermissions();

  const {
    levels,
    roleList,
    selectedRole,
    fetchRoleList,
    setSelectedRole,
    getDepartmentByValue,
    getSubDepartmentByValue,
  } = useRoles();

  const [params, setParams] = useState({
    page: 1,
    pageSize: 30,
    search: '',
    level: levels.AGENCY_LEVEL,
    status: 'ACTIVE',
    sorts: 'seniorityLevel:ASC',
    attributes: [
      'code',
      'name',
      'color',
      'roleId',
      'status',
      'usersCount',
      'department',
      'subDepartment',
      'supervisorRole',
      'isDefault',
      'hasAccessToAllClients',
    ],
    include: ['reportingRole'],
  });

  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openReassignRoleModal, setReassignRoleModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => await fetchRoleList(params);

    fetchData().catch(console.error);
  }, [params]);

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Role',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      classes: 'text-13 leading-1.5 p-4 whitespace-nowrap',
      formatter: (cell) => startCase(cell),
    },
    {
      dataField: 'reportingRole.name',
      text: 'Reporting Role',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap',
    },
    {
      dataField: 'department',
      text: 'Department',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      headerClasses: 'text-center text-grayscale-600 text-13',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap',
      formatter: (cell, row) => {
        if (!cell) return '';
        const department = getDepartmentByValue(cell);
        const subDepartment = getSubDepartmentByValue(row.subDepartment);

        return (
          <div className="flex w-full justify-center gap-2 items-center">
            {row.subDepartment && (
              <span
                className="py-2 px-4 rounded-full"
                style={{
                  color: subDepartment?.color,
                  backgroundColor: subDepartment?.bgColor,
                }}
              >
                {startCase(lowerCase(row.subDepartment))}
              </span>
            )}
            <span
              className="py-2 px-4 rounded-full"
              style={{
                color: department?.color,
                backgroundColor: department?.bgColor,
              }}
            >
              {startCase(lowerCase(row.department))}
            </span>
          </div>
        );
      },
    },
    {
      dataField: 'hasAccessToAllClients',
      text: 'Has Acces to all Clients',
      sort: true,
      headerStyle: {
        minWidth: '100px',
      },
      headerClasses: 'text-center text-grayscale-600 text-13',
      classes: 'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap',
      formatter: (cell) => {
        return (
          <div className="flex w-full justify-center gap-2 items-center">
            <span
              className="py-2 px-4 rounded-full"
              style={{
                color: cell ? '#00966D' : '#C30000',
                backgroundColor: cell ? '#CFFFDD' : '#FFAFAF',
              }}
            >
              {cell ? 'Yes' : 'No'}
            </span>
          </div>
        );
      },
    },
    {
      dataField: 'usersCount',
      text: 'Employee Count',
      sort: true,
      headerStyle: {
        minWidth: '100px',
      },
      classes:
        'text-grayscale-800 text-13 leading-1.5 p-4 text-right whitespace-nowrap',
    },
    {
      dataField: 'isDefault',
      text: 'Action',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      classes:
        'text-grayscale-800 text-13 leading-1.5 p-4 whitespace-nowrap flex justify-center items-center',
      formatter: (cell, row) => (
        <div className="flex items-center gap-2 justify-center">
          {(isAgencySuperUser() ||
            userCan(
              'admin.roles.permisions.view|admin.roles.permisions.update'
            )) && (
            <Link to={`/employees/roles/${row.roleId}/permissions`}>
              <KeyIcon className="mr-1 w-5 h-5 inline text-gray-500 cursor-pointer" />
            </Link>
          )}
          <span>
            <PencilIcon
              className={`mr-1 w-5 h-5 inline text-gray-500 ${
                !userCan('roles.edit') ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
              onClick={() => {
                if (userCan('roles.edit')) {
                  setSelectedRole({ loading: false, data: row });
                  setOpenRoleModal(true);
                }
              }}
            />
          </span>

          <span>
            <TrashIcon
              className={`mr-1 w-5 h-5 inline text-error ${
                cell || !userCan('roles.delete')
                  ? 'cursor-not-allowed'
                  : 'cursor-pointer'
              }`}
              onClick={() => {
                if (!cell && userCan('roles.delete')) {
                  setSelectedRole({ loading: false, data: row });
                  setOpenDeleteModal(true);
                }
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  // Handle table change.
  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };

    if (sortField && sortOrder) {
      newParams.sorts = `${sortField}:${sortOrder}`;
    }

    setParams(newParams);
  };

  const deleteRole = async () => {
    await axios
      .delete(`/v2/roles/${selectedRole.data.roleId}`)
      .then((response) => {
        popupAlert({ type: 'success', title: response.data.message });
        setSelectedRole({ loading: true, data: {} });
        fetchRoleList(params);
      })
      .catch((error) => {
        popupAlert({
          type: 'error',
          title: error.response.data.message,
        });
      });

    setOpenDeleteModal(false);
  };

  const onSearch = debounce((e) => {
    setParams({ ...params, search: e.target.value });
  }, 500);

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="grid grid-cols-4 mt-5 gap-1">
          <h2 className="font-inter font-bold text-25 tracking-3/4 leading-1.2 text-grayscale-900">
            Roles
          </h2>

          {userCan('roles.create') && (
            <button
              onClick={() => setOpenRoleModal(true)}
              className="w-24 border-2 border-secondary rounded-40 font-bold text-sm leading-1.5 tracking-2 text-secondary flex items-center justify-center px-4 py-1.5 space-x-2"
            >
              <PlusIcon className="w-3 h-3" />
              <span>Add</span>
            </button>
          )}
        </div>

        <InputPrepend
          name="search"
          onChange={(e) => onSearch(e)}
          type="text"
          placeholder="Search"
          prependText={<SearchIcon className="w-4 h-4" />}
          border="border-none"
          classes="text-base"
          autoFocus
        />
      </div>

      <Navigation />

      <div className="mt-8">
        {userCan('roles.list') && (
          <Table
            columns={tableColumns}
            data={roleList.data}
            onTableChange={onTableChange}
            params={params}
            keyField="roleId"
            loading={roleList.loading}
          />
        )}
      </div>

      <ConfirmationModal
        title={
          selectedRole.data?.usersCount
            ? 'This role has employees reporting to it. Are you sure you want to delete it?'
            : 'Are you sure you want to delete this role?'
        }
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onOkClick={() => {
          if (selectedRole.data?.usersCount) {
            setOpenDeleteModal(false);
            setReassignRoleModal(true);

            return;
          }

          deleteRole();
        }}
        onCancelClick={() => {
          setSelectedRole({ loading: true, data: {} });
          setOpenDeleteModal(false);
        }}
        cancelText="Cancel"
        okText="Delete"
        size="lg"
        titleClass="font-bold text-xl px-4 py-2"
        okButtonClassName="flex items-center justify-center bg-error-dark px-8 py-2 rounded-full text-white text-sm leading-5 tracking-widest font-bold"
        showOkLoading={true}
      />

      <ReassignRoleModal
        role={selectedRole.data}
        open={openReassignRoleModal}
        setOpen={setReassignRoleModal}
        onSuccess={() => {
          setReassignRoleModal(false);
          setSelectedRole({ loading: true, data: {} });
          fetchRoleList(params);
        }}
      />

      <FormModal
        open={openRoleModal}
        setOpen={setOpenRoleModal}
        currentRole={selectedRole.data}
        setCurrentRole={setSelectedRole}
        onSuccess={() => {
          setSelectedRole({ loading: true, data: {} });
          fetchRoleList(params);
        }}
      />
    </div>
  );
};

export default Roles;
