import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchMaintenanceSettings = createAsyncThunk(
  'settings/fetchMaintenance',
  async () => {
    const response = await axios.get('/settings/maintenance-status');
    return response.data.data.value;
  }
);

export const fetchArchiveActivityLogsAfterInMonths = createAsyncThunk(
  'settings/fetchMaintenance',
  async () => {
    const response = await axios.get('/settings/archive-logs-after-in-months');

    return response.data.data.value;
  }
);

export const updateMaintenanceSettings = createAsyncThunk(
  'settings/updateMaintenance',
  async (value) => {
    const response = await axios.post('/settings/maintenance-status', {
      value,
    });

    return response.data.data.value;
  }
);

export const updateArchiveActivityLogsAfterInMonths = createAsyncThunk(
  'settings/updateArchiveActivityLogsAfterInMonths',
  async (value) => {
    const response = await axios.post(
      '/settings/archive-logs-after-in-months',
      {
        value,
      }
    );

    return response.data.data.value;
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    maintenance: false,
    archiveActivityLogsAfterInMonths: '6',
  },
  reducers: {
    setMaintenance: (state, action) => {
      state.maintenance = action.payload;
    },
  },
  extraReducers: {
    [fetchMaintenanceSettings.fulfilled]: (state, { payload }) => {
      state.maintenance = payload;
    },
    [updateMaintenanceSettings.fulfilled]: (state, { payload }) => {
      state.maintenance = payload;
    },
    [fetchArchiveActivityLogsAfterInMonths.fulfilled]: (state, { payload }) => {
      state.archiveActivityLogsAfterInMonths = payload;
    },
    [updateArchiveActivityLogsAfterInMonths.fulfilled]: (
      state,
      { payload }
    ) => {
      state.archiveActivityLogsAfterInMonths = payload;
    },
  },
});

export const { setMaintenance } = settingsSlice.actions;

export default settingsSlice.reducer;
