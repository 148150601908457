import SlideOver from 'components/SlideOver';
import { useEffect, Fragment, useState, useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { object, string } from 'yup';
import ReactTooltip from 'react-tooltip';
import {
  ClipboardIcon,
  XIcon,
  ExternalLinkIcon,
  UploadIcon,
} from '@heroicons/react/outline';
import axios from 'axios';

import Label from 'components/Forms/Label';
import Button from 'components/Button';
import CustomCalendar from 'components/Calendar';
import useClientAssignees from 'features/clients/Client/useClientAssignees';

const LeadSlideOver = ({
  open,
  setOpen,
  onUpdateLead,
  formRef,
  copyToClipboard,
  onUploadImage,
  onPhasteImage,
  status,
}) => {
  const { SALES_REPRESENTATIVE_ASSIGNEE } = useClientAssignees();
  const [salesReps, setSalesReps] = useState([]);

  useEffect(() => {
    ReactTooltip.rebuild();
    axios
      .get('/v2/users', {
        params: {
          filterGroups: JSON.stringify([
            {
              filters: [
                {
                  attribute: 'role.code',
                  operator: 'IN',
                  value: SALES_REPRESENTATIVE_ASSIGNEE.allowedRoles.map(
                    (role) => role.value
                  ),
                },
              ],
            },
          ]),
        },
      })
      .then((res) => {
        setSalesReps(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const timezone = 'America/New_York';

  // 1) Add BD fields into your validation schema
  const validationSchema = object().shape({
    date: string().required('Date is required'),
    time: string().required('Time is required'),
    bdDate: string(), // or .required() if needed
    bdTime: string(), // or .required() if needed
    timeNotes: string(), //.required('Time Notes is required'),
  });

  // 2) Add BD fields to initial data
  const data = {
    date: '',
    time: '',
    screenShot: '',
    screenShotDate: '',
    bdDate: '', // <-- new
    bdTime: '', // <-- new
    timeNotes: '',
  };

  const onSubmit = (values) => {
    let newData = {};

    if (
      ['Accepted Positive', 'Accepted Neutral', 'Accepted Negative'].includes(
        status
      )
    ) {
      newData.dateTimeOfResponse = `${values.date} ${values.time}:00`;
      newData.responseDateCallScreenshot = values.screenShot;
      newData.dateTimeWeResponded = values.screenShotDate;

      // Add BD date/time
      newData.dateTimeOfBDResponse = `${values.bdDate} ${values.bdTime}:00`;
      newData.timeNotes = values.timeNotes;
    } else {
      const [userId, email] = values.salesRep.split(',');
      newData.dateOfCall = `${values.date} ${values.time}:00`;
      newData.dateOfCallScreenshot = values.screenShot;
      newData.dateBooked = values.screenShotDate;
      newData.salesRep = userId;
      newData.salesRepEmail = email;

      // Add BD date/time if relevant for these statuses
      // newData.dateTimeOfBDResponse = `${values.bdDate} ${values.bdTime}:00`;
    }

    onUpdateLead({ ...formRef.current.values, ...newData });
    setOpen(false);
  };

  return [
    'Accepted Positive',
    'Accepted Neutral',
    'Accepted Negative',
  ].includes(status) ? (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={'Response'}
      titleClasses="capitalize"
      size="3xl"
    >
      <div className="flow-root">
        <Formik
          initialValues={data}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ handleChange, setFieldValue, values, handleSubmit }) => (
            <Form>
              <div className="grid grid-cols-12 gap-4">
                {/* Original date/time fields */}
                <div className="col-span-12 mt-6 flex justify-center">
                  <h2>Lead Response</h2>
                </div>
                <div className="col-span-6">
                  <Label>DATE</Label>
                  <Field
                    name="date"
                    placeholder="Key"
                    className="form-select text-sm"
                    onChange={handleChange}
                    type="date"
                    timezone={timezone}
                  />
                  <ErrorMessage
                    name="date"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-6">
                  <Label>TIME (EST)</Label>
                  <Field
                    name="time"
                    placeholder="TIME (EST)"
                    className="form-select text-sm"
                    onChange={handleChange}
                    type="time"
                    timezone={timezone}
                  />
                  <ErrorMessage
                    name="time"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>

                <div className="col-span-12 mt-6 flex justify-center">
                  <h2>BD Response</h2>
                </div>
                {/* NEW BD date/time fields */}
                <div className="col-span-6">
                  <Label>DATE</Label>
                  <Field
                    name="bdDate"
                    placeholder="BD Date"
                    className="form-select text-sm"
                    onChange={handleChange}
                    type="date"
                  />
                  <ErrorMessage
                    name="bdDate"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>
                <div className="col-span-6">
                  <Label>TIME (EST)</Label>
                  <Field
                    name="bdTime"
                    placeholder="BD Time (EST)"
                    className="form-select text-sm"
                    onChange={handleChange}
                    type="time"
                  />
                  <ErrorMessage
                    name="bdTime"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>

                <div className="col-span-12">
                  <Label>Time Notes</Label>
                  <Field
                    as="select"
                    name="timeNotes"
                    className="form-select text-sm"
                    onChange={handleChange}
                  >
                    <option value="">Select Time Notes</option>
                    <option value="During Shift">During Shift</option>
                    <option value="During Shift - Special Cases">
                      During Shift - Special Cases
                    </option>
                    <option value="During Shift - No Notif">
                      During Shift - No Notif
                    </option>
                    <option value="Out of Office Hours">
                      Out of Office Hours
                    </option>
                    <option value="Out of Office Hours - Special Cases">
                      Out of Office Hours - Special Cases
                    </option>
                    <option value="Out of Office Hours - No Notif">
                      Out of Office Hours - No Notif
                    </option>
                    <option value="Weekend">Weekend</option>
                  </Field>
                  <ErrorMessage
                    name="timeNotes"
                    component="div"
                    className="text-red-700 font-normal text-xs"
                  />
                </div>

                {/* Screenshot handling, as you already have it */}
                {values.screenShot ? (
                  <div className="col-span-12">
                    <Label>
                      Screenshot
                      <XIcon
                        onClick={() => setFieldValue('screenShot', '')}
                        className="cursor-pointer ml-2 inline h-5 w-5"
                        color="gray"
                        data-tip="Change screenshot"
                      />
                      <ExternalLinkIcon
                        onClick={() => {
                          let image = new Image();
                          image.src = values.screenShot;
                          window.open('').document.write(image.outerHTML);
                        }}
                        className="cursor-pointer ml-2 inline h-5 w-5"
                        color="gray"
                        data-tip="Open in new tab"
                      />
                      <ClipboardIcon
                        onClick={() => copyToClipboard(values.screenShot)}
                        className="cursor-pointer ml-2 inline h-5 w-5"
                        color="gray"
                        data-tip="Copy image"
                      />
                      <ReactTooltip
                        place="bottom"
                        className="max-w-xs text-black"
                        backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                        textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                      />
                    </Label>
                    <img
                      id="target"
                      src={values.screenShot}
                      data-tip={values.screenShotDate}
                      className="border-2 border-indigo-600"
                    />
                    <ReactTooltip
                      place="bottom"
                      className="max-w-xs text-black"
                      backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
                      textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
                    />
                  </div>
                ) : (
                  <div className="col-span-12">
                    <div className="flex flex-row justify-between">
                      <div>
                        <Label>Screenshot</Label>
                      </div>
                      <div>
                        <label htmlFor="upload" title="Upload image">
                          <UploadIcon
                            className="h-5 w-5 cursor-pointer"
                            color="gray"
                            data-tip="Upload image"
                          />
                        </label>
                        <input
                          id="upload"
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={(e) =>
                            onUploadImage(
                              e,
                              setFieldValue,
                              'screenShot',
                              'screenShotDate'
                            )
                          }
                        />
                      </div>
                    </div>
                    <Field
                      name="screenShot"
                      placeholder="Paste image here"
                      className="form-select text-sm"
                      onPaste={(e) =>
                        onPhasteImage(
                          e,
                          setFieldValue,
                          'screenShot',
                          'screenShotDate'
                        )
                      }
                      as="textarea"
                    />
                    <ErrorMessage
                      name="screenShot"
                      component="div"
                      className="text-red-700 font-normal text-xs"
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-between">
                <button
                  onClick={() => setOpen(false)}
                  className="mt-2"
                  color="green"
                >
                  Cancel
                </button>
                <Button
                  onClick={() => console.log(values)}
                  type="submit"
                  classes="mt-2"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </SlideOver>
  ) : (
    // The "else" portion with <CustomCalendar /> remains as is, or add BD fields as well
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ handleChange, setFieldValue, values, handleSubmit, errors }) => (
        <Form>
          <CustomCalendar
            salesReps={salesReps}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            open={open}
            setOpen={setOpen}
            values={values}
            errors={errors}
          />
        </Form>
      )}
    </Formik>
  );
};

export default LeadSlideOver;
